
@media (max-width: 769px) {  
  .no-mobile{
    display: none  !important;
  }
}

.special-font{
  font-family: 'Inter', sans-serif;
}

.bold{
  font-weight: 900;
}

.semibold{
  font-weight: 600;
}

.big-font{
  font-size: 32px !important;
}

.medium-font{
  font-size: 22px !important;
}


.cluuf-app {
  padding-top: 0px;
  margin: 0 auto;
  max-width: 100%;
}

.ks-text-align-center {
  text-align: center;
}

.ks-text-align-left {
  text-align: left;
}

.ks-text-align-right {
  text-align: right;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: var(--white);
}

.extern-form-cluuf{
  background: #fff;
    border-radius: external-form5px;
    padding: 10px 30px;
    height: 450px;
}

.notification-alert-success .ant-message-notice-content {
  background: var(--green);
  color: var(--white);
}

.notification-alert-success .anticon {
  color: var(--white) !important;
}

.spin-global {
  padding: 40px;
  margin: auto;
  max-width: 100%;
  width: 100%;
}


.ant-table-container .ant-select-selector {
  height: 20px !important;
}

.ant-table-container .ant-select.ant-select-single {
  font-size: 13px;
}

.ant-table-container .ant-select.ant-select-single .ant-select-selection-item {
  line-height: 20px;
}

.ant-input[disabled],
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: var(--black) !important;
}

.calendar-screen {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.calendar-screen {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.rbc-calendar {
  height: 100vh;
}

.ant-picker {
  width: 100%;
}

.subcontainer-wrapper {
  padding: 2px 20px;
}

.bt {
  border: none;
}

.bt-primary,
.bt-primary:hover,
.bt-primary:active {
  background: var(--primary) !important;
  color: var(--white) !important;
}

.ant-input-number {
  width: 100% !important;
}

.logo h2 {
  padding: 5px;
  color: #ff7a5a;
  text-align: center;
  max-width: 100px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.ant-list-item-meta {
  margin-left: 15px!important;
}

.ql-editor {
  height: 250px !important;
}

.ql-editor-wrapper-100 .ql-editor {
  height: 100px !important;
}

.ql-editor-wrapper-150 .ql-editor {
  height: 150px !important;
}

.ql-editor-wrapper-250 .ql-editor {
  height: 250px !important;
}

.ql-editor-wrapper-350 .ql-editor {
  height: 350px !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 1% !important;
}

.primaryOption {
  color: green !important;
  background-color: hsl(75, 81%, 75%);
  padding: 7px 3px;
  border-radius: 5px;
  text-align: center;
}

.secondaryOption {
  background-color: rgb(124, 0, 128) !important;
  color: hsl(75, 81%, 75%);
  padding: 7px 3px;
  border-radius: 5px;
  text-align: center;
}

.textGreen {
  color: green !important;
}

.iconGreen {
  font-size: 20px;
  color: green !important;
}

.iconRed {
  font-size: 20px;
  color: red !important;
}

/*
.ant-statistic.size100 {
  margin-right: 30px;
}


.ant-statistic-title {
  color: #000 !important;
  font-weight: 600;
  font-size: 9px !important;
  text-transform: uppercase;
}

*/
button.ant-btn {
  background: var(--cluuf) !important;
}

.ant-statistic-content {
  font-size: 16px !important;
}


.checked-box {
  padding: 4px 10px;
  border: 2px solid gray !important;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
}

.type-pack{
  font-size: 26px;
}

.image{
  color: #4c8c11;
}

.text{
  color: #5e9bf7;
}

.form{
  color: #a907b8;
}

.type-pack-global{
  position: absolute;
  background: var(--cluuf) !important;
  padding: 10px;
  /* border: 1px solid #e6e6e6; */
  border-radius: 10px;
  top: 10px;
  right: -20px;
  color: #fff;
  font-size: 32px;
}

.body-page{
  background: var(--white);
  outline: .1rem solid #0000;
}

.ant-table-wrapper{
  background: var(--white);
}

.ant-page-header{
  padding: 20px !important;
  background: "red" !important;
}


.ant-page-header-footer{
  background: #fff;
  position: relative;
  left: -10px;
  padding-left: 10px;
  width: 102%;
}


.tag-button-table:hover{
transform: scale(1.05);
}


.tag-button-table.ant-btn{
  background:#47525d99 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 3px 20px;
}

.tag-button-table.ant-btn-primary{
  background: var(--cluuf) !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 3px 20px;
}

.ant-table .anticon svg {
  display: inline-block;
  font-size: 16px !important;
  text-align: center !important;
  color: #0006 !important
}

.ant-table .danger svg {
  color: var(--light-orange) !important
}


.ant-table span.title-row {
  color: var(--cluuf) !important;
  text-transform: uppercase !important;
}


.ant-table.ant-table-small {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 300;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 35px !important;
  line-height: 40px !important;
}

.ant-menu li.ant-menu-item span{
  position: relative;
  top: -3px;
  font-size: 19px;
}

p.company-name{
  text-align: center;
  font-size: 9px;
  color: #87d6e4;
}

.ant-layout-sider {
  background: var(--cluuf) !important;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--cluuf) !important;
  font-family: 'Quicksand', sans-serif;
}

.ant-layout-sider-children .ant-avatar {
  width: 42px !important;
  height: 42px !important;
  border: 3px solid #fff !important;
}

.ant-page-header-heading .ant-page-header-heading-title {
  color: #000000d9;
  font-weight: 400;
  font-size: 22px;
  font-family: 'Quicksand', sans-serif;
}

.ant-table-thead > tr > th {
  font-weight: 200 !important;
  font-family: 'Quicksand', sans-serif;
  font-size: 12px !important;
  background: var(--cluuf) !important;
}

.ant-table-content table thead.ant-table-thead th {
  background-color: var(--cluuf) !important;
  color:hsl(75, 81%, 75%)  !important;
}

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  /* font-size: 12px !important; */
}

h2.logo-cluuf{
  font-family: 'Quicksand', sans-serif !important;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}

.excerpt-pack-tour .ql-editor{
  height: 100px !important;
}

.label-consolidate-button-filter{
  position: absolute;
  top: -10px;
  left: -5px;
  background: #fff;
  font-size: 10px;
  padding: 0px 5px;
  border-radius: 10px;
}

.fieldset-consolidate-filters{
  padding: 10px 0px;
  position: relative;
  margin-right: 50px;
  color: #8c8c8c;
  left: -3px;
}


.container-subpages{
  margin-bottom: 10px;
  padding: 10px 10px;
  background:#fff;
  border-radius:5px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(227,227,227,1);
-moz-box-shadow: 0px 0px 5px 3px rgba(227,227,227,1);
box-shadow: 0px 0px 5px 3px rgba(227,227,227,1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
  margin-left: 30px;
}

button.ant-btn.tag-button-table.btn-alternative {
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
  color: gray;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  padding: 0 10px;
  text-transform: uppercase;
}



button.ant-btn.tag-button-table.btn-alternative.ant-btn-primary {
  border-color: #40a9ff !important;
  background-color: #40a9ff !important;
  border: 1px solid #e5e5e5;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  padding: 0 10px;
  text-transform: uppercase;
}

.ant-layout .ant-btn-icon-only.ant-btn-lg{
  width: 25px;
  height: 25px;
  padding: 3px;
  font-size: 10px;
  border-radius: 5px;
  margin-top: -20px;
  background: gray;
}

.section-check-options{
  padding: 10px 10px;
  background: #cdeba7;
}

.input-filters-form {
  height: 40px;
  position: relative;
  top: -4px;
}

.container-statistic{
  background: #fff;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin: 0px 10px 0px 0px;
  box-shadow: 0px 0px 5px 3px #e3e3e3;
}

.main-header .ant-page-header {
  background:var(--cluuf) !important;
  padding: 10px 20px !important;

}

.ant-page-header-heading-title{
  color: #fff !important;
  font-family: 'Inter', sans-serif;
  font-weight: bold !important;
  font-size: 28px !important;
}

.ant-page-header-heading-sub-title{
  color: #fff !important
}

.margin-bottom-10{
  margin-bottom: 10px !important;
}

.title-submenu{
  text-align: center;
    color:hsl(51, 100%, 50%);
    padding: 10px 0px;
    font-weight: bold;
    font-size: 14px;
}

button.ant-btn.secondary{
  background: #fff !important;
  color: #656262 !important;
  border: none !important;
  font-weight: 500 !important;
  border: 1px solid #c3c3c4 !important;
}

button.ant-btn.secondary.success{
  background: #ebffd8 !important;
  color: #528552 !important;
  border-color: #74bd74 !important;
}

button.ant-btn.secondary.warning{
  background: #c16809 !important;
  color: #528552 !important;
  border-color: #74bd74 !important;
}

button.ant-btn.secondary.info{
  color: #fffcfd !important;
  border: none !important;
  height: 31px;
  padding:7px 12px;

  background: #2692c9 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 7px;
}

button.ant-btn.secondary.info svg{
  color: #fffcfd !important;
}


button.ant-btn.secondary.error{
  color: #fffcfd !important;
  border: none !important;
  height: 31px;
  background: #b33536 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 10px;
  padding:7px 12px;

  letter-spacing: 1px;
  margin-bottom: 7px;
}


button.ant-btn.secondary.error svg{
  color: #fff !important;
}



button.ant-btn.secondary.primary{
  color: #fffcfd !important;
  border: none !important;
  padding:7px 12px;
  height: 31px;
  background: linear-gradient(90.44365314254742deg, rgba(83, 94, 194,1) 26.430555555555554%,rgba(83, 94, 194,1) 27.60763888888889%,rgba(83, 94, 194,1) 89.20833333333333%)
  !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 7px;
}


table button.ant-btn.secondary.primary,
table button.ant-btn.secondary.error,
table button.ant-btn.secondary.info{
  padding: 5px 7px !important;
}

button.ant-btn.secondary.primary.fill{
    color: #535ec2 !important;
    border: 3px solid #535ec2 !important;
    height: 33px;
    background: #0000 !important;   
}

button.ant-btn.secondary.primary.fill svg{
  color: #535ec2 !important;
}

button.ant-btn.secondary.primary.fill:hover{
  color: #fff !important;
  background: #535ec2 !important;   
}

button.ant-btn.secondary.primary.fill:hover svg,
button.ant-btn.secondary.error.fill:hover svg,
button.ant-btn.secondary.info.fill:hover svg{
  color: #fff !important;
}

button.ant-btn.secondary.error.fill{
  color: #b33536 !important;
  border: 2px solid #b33536 !important;
  height: 33px;
  background: #0000 !important;   
}

button.ant-btn.secondary.error.fill:hover{
  color: #fff !important;
  background: #b33536 !important;   
}

button.ant-btn.secondary.error.fill svg{
  color: #b33536 !important;
}


button.ant-btn.secondary.info.fill{
  color: #2692c9 !important;
  border: 2px solid #2692c9 !important;
  height: 33px;
  background: #0000 !important;   
}

button.ant-btn.secondary.info.fill:hover{
  color: #fff !important;
  background: #2692c9 !important;   
}

button.ant-btn.secondary.info.fill svg{
  color: #2692c9 !important; 
}


button.ant-btn.secondary.white{
  color: #fffcfd !important;
  border: #fffcfd 2px solid !important;
  background: transparent !important;
  height: 30px;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  letter-spacing: 2px;
}

button.ant-btn.secondary.white:hover{
  color: #000 !important;
  background: #fff !important;
 transform: scale(1.1);
}

button.ant-btn.secondary.primary.large,
button.ant-btn.secondary.error.large,
button.ant-btn.secondary.info.large{
  height: 50px;
}


button.ant-btn.secondary.primary svg, 
button.ant-btn.secondary.info svg,
button.ant-btn.secondary.success svg,
button.ant-btn.secondary.error svg
{
  color: #fffcfd !important;
  font-size: 16px;
  
   }

a.secondary.primary{
  color: #fffcfd !important;
  border: none !important;
  height: 30px;
  background: linear-gradient(85deg, #2EB67D, #007a5a) !important;
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 14px;
}

    a.secondary.primary svg{
      color: #e01e5a !important;
       }

   a.secondary.primary:hover{
      transform: scale(1.1) !important;
        }    


button.ant-btn.warning{
  background: #ff7b5a14 !important;
  color: #ff7a5a !important;
  border: none !important;
  font-weight: 500 !important;
  border: 1px solid #ff7a5a !important;
}

button.ant-btn.warning:hover{
 transform: scale(1.1);
}


button.ant-btn.secondary:hover{
  border: 3px solid rgb(34, 34, 36);
  transform: scale(1.1);
}





button.ant-btn-icon-only{
  background: transparent !important;
  color: black;
  border: none;
}

button.ant-btn-icon-only:hover{
  background: transparent !important;
  color: var(--cluuf) !important;
  border: none;
  transform: scale(1.2);
}


@media (max-width: 600px) {
  button.ant-btn.secondary.primary.big-mobile,
  button.ant-btn.secondary.info.big-mobile,
  button.ant-btn.secondary.error.big-mobile,
  button.ant-btn.secondary.success.big-mobile{
    font-size: 28px;
    height: 50px;
  }

  button.ant-btn.secondary.primary.big-mobile svg,
  button.ant-btn.secondary.info.big-mobile svg,
  button.ant-btn.secondary.error.big-mobile svg,
  button.ant-btn.secondary.success.big-mobile svg{
    font-size: 28px !important;
    padding: 3px;
  }

  .isMinimal-mobile tr:nth-child(odd) {
    background: #e1f0fd;

  }
  .isMinimal-mobile  tr:nth-child(even) {
    background: #f7fada;
  }

  .isMinimal-mobile  td{
    padding: 7px !important;
  }



  


}



.external-form textarea.ant-input {
  font-size: 24px !important;
}

table a .anticon:hover{
transform: scale(1.3);
}

.tag-gray{
  background: #e3e3e3;
  color: gray;
  border: #000 1px solid;
}

.dashboard .ant-page-header-heading .ant-page-header-heading-title{
  color: var(--cluuf) !important;
  font-weight: 800;
}

.site-layout-background .ant-page-header-heading .ant-page-header-heading-title{
  color: var(--cluuf) !important;
  font-weight: 800;
}

.site-layout-background .ant-page-header-heading .ant-page-header-heading-sub-title{
  color: var(--cluuf) !important;
}
.center-buttons{
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageAumented:hover{
  transform: scale(1.5);
  cursor:pointer;
}


.qr-section-external{
  display: flex;
  padding-top: 70px;
  width: 100%;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.qr-section-external .qr-wrapper{
  max-width: 220px;
  width: 220px;
}

.qr-section-external .avatar-user{
  width: 80px;
  border-radius: 5px;
  height: 80px;
  margin: auto;
  display: block;
  margin-bottom: 20px;
  border-radius: 50%
}

.qr-section-external h2{
text-align: left;
}

.qr-section-external p{
  text-align: left;
  }
  

  .qr-section-external .carnet-fototo{
    max-width: 120px;
    width: 120px;
    position: absolute;
    top: -350px;
    left: -170px;
  }



.qr-section-external .wrapper{
  background-image: url("../assets/carnet.png");
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
}

.commment{
  padding: 10px 30px;
    background: #e7f4ff !important;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}

table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 30px !important;
  padding: 0 11px;
  font-size: 15px;
  padding-top: 3px;
  letter-spacing: 1px;
  font-weight: 600;
}

.DescriptionsTitle{
  background: rgb(227, 227, 227);
}

.site-statistic-demo-card {
  padding: 30px;
  background: #ececec;
}

.big-font{
  font-size: 20px;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.color-red input{
  color: red !important;
  font-weight: bold;
}

.search-user-analist-header{
  background: #fff;
  padding: 5px 20px !important;
  max-width: 95%;
  margin: auto;
  position: relative;
  -box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px;
  top: -25px;
}

.magicoxxx{
  display: none;
  position: absolute;
  top: 0px;
  background-color: var(--cluuf);
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 24px;
  padding-left: 20px;
  padding-top: 20px;
  color: #ffded8;
  font-size: 14px;
}

.usermagico{
  border: none;
transition: all 0.5s ease-out;
}


.card-plans{
  border: none;
  -webkit-box-shadow: 0px -1px 8px 4px rgba(214,211,214,1);
-moz-box-shadow: 0px -1px 8px 4px rgba(214,211,214,1);
box-shadow: 0px -1px 8px 4px rgba(214,211,214,1);
transition: all 0.5s ease-out;

}

.usermagico .ant-card-body,
.card-plans .ant-card-body{
  padding: 0px;
  background-size: cover;
  border-radius: 7px;
  height: 100%;
  color: #343a79;
  line-height: 25px;
  background: #f7f7f7;
}

.usermagico h2,
.card-plans h2{
  color: #fff;
  font-weight: 600 !important;
}

.usermagico h3,
.card-plans h3
{
    color: #fff;
    padding: 10px;
    text-align: center;
    position: relative;
    top: -6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}


.usermagico h2 span,
.card-plans h2 span{
  color: #4d4d4d;
  font-weight: 300px;
  font-size: 12px;
}

.magicoxxx.user-inwait-background{
  background-color: rgb(34, 25, 25) !important;
}

.magicoxxx a{
  font-size: 14px;
  color: #fff !important;
  position: relative;
  top: -3px;
  letter-spacing: 1px;
}

.magicoxxx small{
  color: #fff !important
}

.card-plans h2{
  color: #000;
  font-size: 18px;
  margin-top: -10px;
}

.card-plans a{
  color: #000;
  font-size: 14px;

}

.card-plans small{
  position: relative;
  top: -13px
}

.magicoxxx a.ant-typography:hover{
  font-size: 15px;
}

.usermagico:hover .magicoxxx{
  display: block;
  background: rgb(70, 71, 74);
  color: #fff !important;
  transition: all 0.5s ease-out;

}

.magicoxxx a.main-button{
  border: 1px solid #fff !important;
  color: var(--cluuf) !important;
  background: #fff;
  padding: 10px 20px !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
  text-align: center;
}


.ant-drawer-header {
  background: var(--cluuf) !important;
  border-radius:  none !important;
}   

.ant-drawer-header  .ant-drawer-title {
  color: #fff !important;
  font-size: 22px !important;
}


.ant-drawer-header  .anticon svg {
  font-weight: bold;
  color: #fff !important
}

.color-purple{
  display: block;
  color: var(--cluuf) !important;
}
  

.simple-checkin{
  max-width: 300px;
  width: 300px;
  margin: auto;
  margin-top: 100px;
}

.simple-checkin .logo{
margin: auto;
margin-bottom: 30px;
}

.analist-layout .ant-layout-header{
  display: block !important;
}

.analist-layout .ant-layout-header {
  color: #fff;
  background: var(--cluuf)  !important;
  height: 80px;
}

.analist-layout .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--cluuf) !important;
  font-weight: bold !important;
  font-size: 16px !important;
}




img.foto-carnet{
  width: 22px;
      margin: auto;
      display: initial;
      border-radius: 50%;
      margin-right: 5px;
      margin-top: -3px;
}

.usermagico h2{
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  text-transform:uppercase;
  font-weight: 200;
}

.tag-status-carnet{
margin: auto;
display: block;
}


.tag-status-carnet.cancel{
  margin: auto;
  display: block;
  position: relative;
  top: -460px;
  background-color: red;
  color: #fff;
  }

.container-interno{
  padding: 0px 50px;
  margin-top: 0px;
  background: #EDF2F5;
  min-height: 800px !important;
  width: 100%;
}

button.ant-btn.third {
  background: #fff !important;
  color: var(--cluuf) !important;
  border: none !important;
  font-weight: 500 !important;
  border: 1px solid var(--cluuf) !important;
}

button.ant-btn.third:hover {
  background: var(--cluuf) !important;
  color: #fff !important;
}

.container-btn-form-float{
  position: absolute;
    top: 60px;
    right: 20px;
    z-index: 999;
    background: #7f7f7f29;
    padding: 10px;
}

.minimal-form .ant-form-vertical .ant-form-item-label {
 padding-bottom: 0px !important;
}

.minimal-form  .ant-form-item-label > label {
  height: 24px !important;
}

.menu-analist{
  margin-left: -8px;
  margin-right: -8px;
  background: #f1f2ff;
  row-gap: 0px;
  padding: 10px;
  margin: auto;
 position: relative; 
 top: -20px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #0084ff00 !important;
}


@media (max-width: 600px) {
  .container-interno{
    padding: 10px !important;
  }
}

.image-pack-container{
  padding: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 7px;
  height:500px;
  background: #f5f5f5;
  margin-bottom: 30px;
}


.ant-tag{
  border: 0px !important;
}


.ant-modal-content .ant-table-content table thead.ant-table-thead th {
  background-color:#47525d !important;
  color:hsl(75, 81%, 75%)  !important;
}

@media (max-width: 600px) {
  .usermagico .ant-card-body .ant-row{
    flex-flow: row wrap;
    justify-content: center;
  }
}

.usermagico .ant-card-body .ant-space{
position: absolute;
    bottom: 4px;
    left: 4px;
}

.center-spin{
  padding: 20px;
  text-align: center;
}

.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 4px !important;
}

.ant-table-body  table tbody tr:nth-child(even) {
	background: #f9f9f9 !important;
}

.card-service{
  background: #f5f5f5;
  box-shadow: 6px 7px 5px 0px rgba(163,163,163,0.75);
  -webkit-box-shadow: 6px 7px 5px 0px rgba(163,163,163,0.75);
  -moz-box-shadow: 6px 7px 5px 0px rgba(163,163,163,0.75);
        padding: 20px;
        border-radius: 10px;
        margin-bottom:  10px;
        color: #fff !important;
}
.card-service h2{
        color: #fff !important;
        font-weight: 600;
}

.card-service p{
  color: #fff !important;
}

.ant-table-thead>tr>th{
  color: #fff !important;
}

.cardAnalistMini{
  padding: 10px 5px;
  text-transform: lowercase;
  font-size: 14px;
  border-radius: 7px;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: #454545;
  font-size: 16px !important;
  font-weight: 800;
  /* font-family: 'Inter', sans-serif; */
  top: 7px;
  left: 3px;
}

.gradient-card{
  background: linear-gradient(120.11261810000337deg, rgba(59, 83, 163,1) 41.463815789473685%,rgba(59, 83, 162,1) 41.463815789473685%,rgba(82, 133, 82,1) 61.02141203703704%,rgba(196, 63, 65,1) 97.67726608187135%);
}

.shadow{
  box-shadow: 5px 5px 5px 0px #797b8a6b;
  -webkit-box-shadow: 5px 5px 5px 0px #797b8a6b;
  -moz-box-shadow: 5px 5px 5px 0px #797b8a6b;
  
}


.ant-col.center{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.plans-card-open{
  margin-top: 15px !important;
  min-height: 130px;
  background: #EDF2F5;
  border-left: 20px solid #199896 !important;
}

.plans-card-close{
  margin-top: 15px !important;
  min-height: 130px;
  background: #EDF2F5;
  border-left: 20px solid #b33536 !important;
}

.apps-card-username{
  font-size: 18px;
  display: flex;
  font-weight: 800;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 5px;
}

.ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 800;
}

.color-primary{
  color: var(--cluuf) !important;
}

.ant-page-header-heading-title{
  color: #000 !important;
}

.ant-page-header-heading-sub-title{
  color: #000 !important;
}

.icons-events-back .ant-page-header-heading svg{    
  font-size: 36px;
  position: relative;
  top: -12px;
  font-weight: bold;
  border-radius: 10px;
  background: #d12d01;
  padding: 3px;
  color: #fff;}

  span.code{
    position: absolute;
    top: -15px;
    right: 0px;
    font-size: 10px;
    color: #485643;
  }
@line-height-base: 1.15;@btn-height-base: 32px;@btn-default-color: #FFFFFF;@btn-default-bg: #727176;@border-radius-base: 5px;@btn-primary-color: #FFFFFF;@btn-primary-bg: #6BB21F;