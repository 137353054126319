/* Override Button Styles */
button {
  /* Default Button */
  &.ant-btn {
    background: var(--raven);

    &:hover {
      color: var(--white);
      background: var(--steel-gray);
      border-color: var(--steel-gray);
    }

    &:active,
    &:focus {
      color: var(--white);
      border-color: var(--raven);
    }
  }

  /* Primary Button */
  &.ant-btn.ant-btn-primary {
    background: var(--kelly-green);


    &:hover {
      color: var(--white);
      background: var(--cluuf);
      border-color: var(--cluuf);
    }

    &:active,
    &:focus {
      color: var(--white);
      border-color: var(--kelly-cluuf);
    }
  }

  /* Custom Actions Button */
  &.ant-btn.ant-btn-actions {
    background-color: var(--white);
    border-color: var(--pale-slate);
    color: var(--cg-blue);

    &:hover {
      color: var(--white);
      background-color: var(--cg-blue);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

/* Override Anchor Styles */
a {
  &.komet-link {
    color: var(--cg-blue);
    text-decoration: underline;
  }

  &.muted {
    color: var(--steel-gray);
    text-decoration: none;
  }

  &.komet-link:hover,
  &.muted:hover {
    color: var(--cg-blue);
    text-decoration: none;
  }
}

a {
  font-size: 12px;

  &.komet-submenu-link {
    color: var(--light-blue);
    text-decoration: underline;
  }

  &.muted {
    color: var(--dark-blue);
    text-decoration: underline;
  }

  &.komet-submenu-link:hover,
  &.muted:hover {
    color: var(--cg-blue);
    text-decoration: none;
  }
}

/* Override Table styles */
table {
  thead.ant-table-thead th {
    color: var(--white);
    background-color: var(--cg-blue) !important;
  }
}

.ant-table.ant-table-small {
  font-size: 12px !important;
}

.ant-layout-header {
  display: none !important;
}

.ant-descriptions-item-label {
  color: #99999a;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.15;
  text-align: start;
}


.ant-layout {
  background: #eff4fb !important;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: auto !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}


.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
  transform: scale(1.2);
}