body{
    background: #47525d;
    zoom: 0.9;
}


.wrapper{
    background-color: #fff;
    padding: 0px 20px;
    max-width:1200px;
    margin: auto;
    height: 1200px;
    margin-top: 50px;
    overflow:scroll;
}

.submit-login{
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    margin: auto;
    padding: 10px 70px;
    height: 60;
    font-size: 20;
    font-weight: 800;
}

.submit-login:hover{
    transform: scale(1.1);
    background-color: #e01e5a !important;
}


.ant-table-content table thead.ant-table-thead th {
    color: #d9f38c !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 10px;
}