.action-bar {
  background: var(--white);
  max-width: 100%;
}

.action-bar-filters {
  min-height: 50px;
  background:  var(--white);
  max-width: 100%;
}

.action-bar-content {
  height: 70px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  background-color: #f6f6f6;
  width: 100%;
  position: relative;
  transition: all 0.5s ease;
  margin-bottom: 30px;
}

.action-bar-close-close-button {
  color: #ccc;
  top: 15px;
  font-size: 22px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}


