:root {
  --christi: #ff8f73;
  --mercury: #e6e6e6;
  --raven: #727176;
  --white-smoke: #f6f6f7;
  --white: #ffffff;
  --black: #000000;
  --purple-cluuf: #5854ef;
  --cluuf: #202549;
  --cg-blue: #041529;
  --steel-gray: #46474a;
  --green: #ff8f73;
  --kelly-green: #ff7a59;
  --pale-slate: #c4c4c4;
  --dark-blue: #041529;
  --light-blue: #041529;
  --light-orange: #ff4d4f;
  --primary: #1b90ff;
  --purple: #52306d;
  --p-table-shadow: 0px 0px 5px var(--p-shadow-from-ambient-light), 0px 1px 2px var(--p-shadow-from-direct-light);
  --p-background: #f6f6f7;
  --p-background-hovered: #f1f2f3;
  --p-background-pressed: #edeeef;
  --p-background-selected: #edeeef;
  --p-surface: #fff;
  --p-surface-neutral: #e4e5e7;
  --p-surface-neutral-hovered: #dbdddf;
  --p-surface-neutral-pressed: #c9ccd0;
  --p-surface-neutral-disabled: #f1f2f3;
  --p-surface-neutral-subdued: #f6f6f7;
  --p-surface-subdued: #fafbfb;
  --p-surface-disabled: #fafbfb;
  --p-surface-hovered: #f6f6f7;
  --p-surface-pressed: #f1f2f3;
  --p-surface-depressed: #edeeef;
  --p-surface-search-field: #f1f2f3;
  --p-backdrop: #00000080;
  --p-overlay: #ffffff80;
  --p-shadow-from-dim-light: #0003;
  --p-shadow-from-ambient-light: #1718180d;
  --p-shadow-from-direct-light: #00000026;
  --p-hint-from-direct-light: #00000026;
  --p-border: #8c9196;
  --p-border-neutral-subdued: #babfc3;
  --p-border-hovered: #999ea4;
  --p-border-disabled: #d2d5d8;
  --p-border-subdued: #c9cccf;
  --p-border-depressed: #575959;
  --p-border-shadow: #aeb4b9;
  --p-border-shadow-subdued: #babfc4;
  --p-divider: #e1e3e5;
  --p-icon: #5c5f62;
  --p-icon-hovered: #1a1c1d;
  --p-icon-pressed: #44474a;
  --p-icon-disabled: #babec3;
  --p-icon-subdued: #8c9196;
  --p-text: #202223;
  --p-text-disabled: #8c9196;
  --p-text-subdued: #6d7175;
  --p-interactive: #2c6ecb;
  --p-interactive-disabled: #bdc1cc;
  --p-interactive-hovered: #1f5199;
  --p-interactive-pressed: #103262;
  --p-focused: #458fff;
  --p-surface-selected: #f2f7fe;
  --p-surface-selected-hovered: #edf4fe;
  --p-surface-selected-pressed: #e5effd;
  --p-icon-on-interactive: #fff;
  --p-text-on-interactive: #fff;
  --p-action-secondary: #fff;
  --p-action-secondary-disabled: #fff;
  --p-action-secondary-hovered: #f6f6f7;
  --p-action-secondary-pressed: #f1f2f3;
  --p-action-secondary-depressed: #6d7175;
  --p-action-primary: #008060;
  --p-action-primary-disabled: #f1f1f1;
  --p-action-primary-hovered: #006e52;
  --p-action-primary-pressed: #005e46;
  --p-action-primary-depressed: #003d2c;
  --p-icon-on-primary: #fff;
  --p-text-on-primary: #fff;
  --p-text-primary: #007b5c;
  --p-text-primary-hovered: #006c50;
  --p-text-primary-pressed: #005c44;
  --p-surface-primary-selected: #f1f8f5;
  --p-surface-primary-selected-hovered: #b3d0c3;
  --p-surface-primary-selected-pressed: #a2bcb0;
  --p-border-critical: #fd5749;
  --p-border-critical-subdued: #e0b3b2;
  --p-border-critical-disabled: #ffa7a3;
  --p-icon-critical: #d72c0d;
  --p-surface-critical: #fed3d1;
  --p-surface-critical-subdued: #fff4f4;
  --p-surface-critical-subdued-hovered: #fff0f0;
  --p-surface-critical-subdued-pressed: #ffe9e8;
  --p-surface-critical-subdued-depressed: #febcb9;
  --p-text-critical: #d72c0d;
  --p-action-critical: #d82c0d;
  --p-action-critical-disabled: #f1f1f1;
  --p-action-critical-hovered: #bc2200;
  --p-action-critical-pressed: #a21b00;
  --p-action-critical-depressed: #6c0f00;
  --p-icon-on-critical: #fff;
  --p-text-on-critical: #fff;
  --p-interactive-critical: #d82c0d;
  --p-interactive-critical-disabled: #fd938d;
  --p-interactive-critical-hovered: #cd290c;
  --p-interactive-critical-pressed: #670f03;
  --p-border-warning: #b98900;
  --p-border-warning-subdued: #e1b878;
  --p-icon-warning: #b98900;
  --p-surface-warning: #ffd79d;
  --p-surface-warning-subdued: #fff5ea;
  --p-surface-warning-subdued-hovered: #fff2e2;
  --p-surface-warning-subdued-pressed: #ffebd3;
  --p-text-warning: #916a00;
  --p-border-highlight: #449da7;
  --p-border-highlight-subdued: #98c6cd;
  --p-icon-highlight: #00a0ac;
  --p-surface-highlight: #a4e8f2;
  --p-surface-highlight-subdued: #ebf9fc;
  --p-surface-highlight-subdued-hovered: #e4f7fa;
  --p-surface-highlight-subdued-pressed: #d5f3f8;
  --p-text-highlight: #347c84;
  --p-border-success: #00a47c;
  --p-border-success-subdued: #95c9b4;
  --p-icon-success: #007f5f;
  --p-surface-success: #aee9d1;
  --p-surface-success-subdued: #f1f8f5;
  --p-surface-success-subdued-hovered: #ecf6f1;
  --p-surface-success-subdued-pressed: #e2f1ea;
  --p-text-success: #008060;
  --p-decorative-one-icon: #7e5700;
  --p-decorative-one-surface: #ffc96b;
  --p-decorative-one-text: #3d2800;
  --p-decorative-two-icon: #af294e;
  --p-decorative-two-surface: #ffc4b0;
  --p-decorative-two-text: #490b1c;
  --p-decorative-three-icon: #006d41;
  --p-decorative-three-surface: #92e6b5;
  --p-decorative-three-text: #002f19;
  --p-decorative-four-icon: #006a68;
  --p-decorative-four-surface: #91e0d6;
  --p-decorative-four-text: #002d2d;
  --p-decorative-five-icon: #ae2b4c;
  --p-decorative-five-surface: #fdc9d0;
  --p-decorative-five-text: #4f0e1f;
  --p-border-radius-base: 0.4rem;
  --p-border-radius-wide: 0.8rem;
  --p-border-radius-full: 50%;
  --p-card-shadow: 0px 0px 5px var(--p-shadow-from-ambient-light), 0px 1px 2px var(--p-shadow-from-direct-light);
  --p-popover-shadow: -1px 0px 20px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
  --p-modal-shadow: 0px 26px 80px var(--p-shadow-from-dim-light), 0px 0px 1px var(--p-shadow-from-dim-light);
  --p-top-bar-shadow: 0 2px 2px -1px var(--p-shadow-from-direct-light);
  --p-button-drop-shadow: 0 1px 0 #0000000d;
  --p-button-inner-shadow: inset 0 -1px 0 #0003;
  --p-button-pressed-inner-shadow: inset 0 1px 0 #00000026;
  --p-override-none: none;
  --p-override-transparent: #0000;
  --p-override-one: 1;
  --p-override-visible: visible;
  --p-override-zero: 0;
  --p-override-loading-z-index: 514;
  --p-button-font-weight: 500;
  --p-non-null-content: '';
  --p-choice-size: 2rem;
  --p-icon-size: 1rem;
  --p-choice-margin: 0.1rem;
  --p-control-border-width: 0.2rem;
  --p-banner-border-default: inset 0 0.1rem 0 0 var(--p-border-neutral-subdued), inset 0 0 0 0.1rem var(--p-border-neutral-subdued);
  --p-banner-border-success: inset 0 0.1rem 0 0 var(--p-border-success-subdued), inset 0 0 0 0.1rem var(--p-border-success-subdued);
  --p-banner-border-highlight: inset 0 0.1rem 0 0 var(--p-border-highlight-subdued), inset 0 0 0 0.1rem var(--p-border-highlight-subdued);
  --p-banner-border-warning: inset 0 0.1rem 0 0 var(--p-border-warning-subdued), inset 0 0 0 0.1rem var(--p-border-warning-subdued);
  --p-banner-border-critical: inset 0 0.1rem 0 0 var(--p-border-critical-subdued), inset 0 0 0 0.1rem var(--p-border-critical-subdued);
  --p-badge-mix-blend-mode: luminosity;
  --p-thin-border-subdued: 0.1rem solid var(--p-border-subdued);
  --p-text-field-spinner-offset: 0.2rem;
  --p-text-field-focus-ring-offset: -0.4rem;
  --p-text-field-focus-ring-border-radius: 0.7rem;
  --p-button-group-item-spacing: -0.1rem;
  --p-duration-1-0-0: 100ms;
  --p-duration-1-5-0: 150ms;
  --p-ease-in: cubic-bezier(0.5, 0.1, 1, 1);
  --p-ease: cubic-bezier(0.4, 0.22, 0.28, 1);
  --p-range-slider-thumb-size-base: 1.6rem;
  --p-range-slider-thumb-size-active: 2.4rem;
  --p-range-slider-thumb-scale: 1.5;
  --p-badge-font-weight: 400;
  --p-frame-offset: 0px;
}


@line-height-base: 1.15;@btn-height-base: 32px;@btn-default-color: #FFFFFF;@btn-default-bg: #727176;@border-radius-base: 5px;@btn-primary-color: #FFFFFF;@btn-primary-bg: #6BB21F;